import React from "react"
import PImage from './images/personal1.jpg'

export const links = [
    {
        link: "#home",
        title: <><span>H</span>OME</>,
        page: '/'
    },
    {
        link: "#about",
        title: <><span>A</span>BOUT</>,
        page: '/'

    },
    {
        link: "#projects",
        title: <><span>P</span>ORTFOLIO</>,
        page: '/'
    },
    {
        link: "#contact",
        title: <><span>C</span>ONTACT</>,
        page: '/'

    },
    // {
    //     link:"#gallery",
    //     title:<><span>G</span>ALLERY</>,
    //     page:'/gallery'
    // }

]

export const homeTexts = {
    link: "Home",
    title:
        <>
            Hi , I’m SALAH SENNOUSSi<br />
            And i’m a Web Developer
        </>,
    description:
        <>
            I’m a fullstack developer and i’m currently providing<br />
            services on freelance platforms .
            <br />
            Email me on <a href="mailto:contact@salaheddine.tech">contact@salaheddine.tech</a> 
        </>,

    links: {
        telegram: "https://t.me/da3az99",
        whatsapp: "https://wa.me/213542176077",
        github: "https://github.com/Da3az",
        stackoverflow: "https://stackoverflow.com/users/18299343/chakib-salah",
        upwork: "https://www.upwork.com/freelancers/~0159e2464c85d7ee1a?"
    }
}

export const aboutTexts = {
    link: "ABOUT",
    text:
        <>
            My name is chakib sennousi , Master degree and self-taught web developer , keeping up with the pace of web technologies ( with some coffe ☕︎ ) is what drives me everyday .<br />
        </>,
    skillsTitle: "Tools I use   :",
    skills:
        [
            [
                'React',
                'NextJS',
                'GatsbyJS',
                'Redux',
            ],
            [
                'TailwindCSS',
                'styled-components',
                'MUI'
            ],
            [
                'NodeJs',
                'NestJS',
                'Prisma',
                'Mongoose',
                'Supabase',
                // 'PassportJS',
            ],
            //    [
            //     'Figma',
            //     'Adobe CC'
            //    ]

        ],
    image: PImage
}

export const projectsTexts = {
    link: "EXPERIENCE",
    high: [
        {
            title: "Dinar Investment ",
            gallery: "dinar",
            description: <>

                &nbsp; &nbsp; Dinar Investment is a funding platform that helps entrepreneurs with the opportunity to obtain flexible and fast funding.

                <br />
                &nbsp; &nbsp; I have been working with Dinar Investment team since March 2022. My primary
                responsibility has been handling the frontend development for various websites.

            </>,
            more: <>
                <br />
                - Built and maintained the company's landing page to represent the image of
                Dinar Investment. The website, developed using GatsbyJS, incorporated
                blogs, news, and several features. Implemented content management using
                Sanity CMS for efficient content administration.

                <br />
                - Developed and maintained a user-side dashboard website. This platform
                handled user signing, profiles, orders, and other actions, ensuring seamless
                user experiences.

                <br />
                -Constructed and managed an admin-side website responsible for handling all
                application features and systems, streamlining operations within the
                organization.


                <br />
                - One of the difficulties faced during the project ,  Initial project phase included multiple urgent milestones with challenging
                ideas. Overcoming these obstacles required clear and concise
                communication, allowing for effective problem-solving. This experience
                greatly enhanced my growth in communication skills and problem-solving
                abilities.

                <br />
                - Overall, my work with Dinar Investment has provided me with valuable experience
                in frontend development, discovering multiple technologies and overcoming
                challenging obstacles.


            </>,
            skills: [
                "NextJS",
                "GatsbyJS",
                "TailwindCSS",
                "SanityCMS",
            ],
            imageKey: "dinar",
            link: "https://dinar.sa/"
        },
        {
            title: "Salasil platform",
            gallery: "salasil",
            description: <>
                &nbsp; &nbsp; Salasil is a platform which is still in the early stages. It is more likely a website where suppliers and buyers can manage their business .
                <br />
                &nbsp; &nbsp; I worked as a front-end developer with a team of back-end developers where I was responsible for integrating APIs and handling their UI.
            </>,
            skills: [
                "React",
                "NextJS",
                "MUI",
                "Typescript",
                "useQuery",
            ],
            imageKey: "salasil",
            link: undefined
        },
        {
            title: "MetaChess",
            gallery: "metachess",
            description: <>
                &nbsp; &nbsp; Front end developer on MetaChess project , where I worked with multiple developers each with different tasks
                using gitlab platform , we used mainly gatsby , redux , socketIo , typescript
                and sass .
                <br />
                &nbsp; &nbsp; I grow my git and deployment skills a lot here and also became more
                used to typescript . mainly responsible with changing the main website
                UI and the 2d game logic and sockets events.
            </>,
            more: <>
                <br />
                Major difficulties I faced during working on this project:
                <br />
                - A lot of developers worked on this projects , some have not followed how the
                project should be (files placement , variables naming , libraries to use) making
                the code really hard to read and to follow .
                <br />
                Tracking all the code and commits , communicating all unclear points with the
                team I was able , a several optimization changes was the key to pass this issue .
                <br />
                - I got tasked with building whitepaper website which have a long
                description and multiple faqs about the application , and it included a lot of
                pages . This was the opportunity to got used to react-markdown to easily generate
                pages following it and the work became totally easy with that .
            </>,
            skills: [
                "React",
                "Typescript",
                "GatsbyJS",
                "Redux",
                "Redux-saga",
                "Sass",
                "Sockets",
                "Markdowns",
            ],
            imageKey: "metaChess",
            link: "https://dev.metachess.network/"
        },
        // {
        //     title: "Serivices website using Gatsby/Node/Stripe",
        //     gallery: "bangalore",
        //     description: <>
        //         &nbsp; &nbsp; A Services website template with admin panel that provides the ability to edit
        //         and change content , also intergated with stripe payment .
        //         <br />
        //         &nbsp; &nbsp; I use this templates for some clients that want ready to go website for their bussiness .
        //     </>,
        //     more: <>
        //         <br />
        //         Major difficulties I faced during making this website were:
        //         <br />
        //         - Drawing charts that display client stripe balance and emails inbox,
        //         made it a good chance to learn and use chartjs which made it simple to handle and with responsivity.
        //         <br />
        //         - Since the data was loaded through graphql on gatsby a redeploy was
        //         needed for each change from the admin panel, and also to load
        //         changes from the products on stripe, however using webhooks solved
        //         the problem.
        //     </>,
        //     skills: [
        //         "React",
        //         "GatsbyJS",
        //         "Nodejs",
        //         "PassportJS",
        //         "Mongoose",
        //         "Stripe",
        //         "Figma",
        //     ],
        //     imageKey: "bangalore_0",
        //     link: "https://banglore-for-translation.vercel.app/"

        // },
        {
            title: "PDF Editor",
            gallery: "pdfEditor",
            description: <>
                &nbsp; &nbsp; Following a client request made a website as a pdf editor , in this website
                user can (extract , merge , rotate, split and other functionalities) .
                <br />
                It also provides user registraion using Supabase .
            </>,
            more: <>
                <br />
                Main difficulty I faced here is to get used with pdf-lib and react-pdf, after that
                everything went smoothly .
            </>,
            skills: [
                "NextJS",
                "TailwindCSS",
                "typescript",
                "Supabase",
                "pdf-lib",
                "react-pdf"
            ],
            imageKey: "pdfEditor_0",
            link: "https://tools4pdf.vercel.app/"

        },
        // {
        //     keys: ["frontend", "backend", "all"],
        //     title: "Management and products selling website",
        //     gallery: "cnc",
        //     description: <>
        //         &nbsp; &nbsp; Following a client request made this website that manage buyers and requests , offers distance calculation for delivery .
        //     </>,
        //     more: <>
        //         <br />
        //         On this site:
        //         <br />
        //         - Users can register and log in, enter their locations and make different
        //         types of orders with the ability to send pictures to clarify the request,
        //         prices can vary according to (product category, measurement, delivery,
        //         type of user...).
        //         <br />
        //         - A control panel that allows the administrator to make various changes
        //         such as removing or changing users' rights, handling orders and
        //         messages, removing products, or changing their settings.
        //         <br />
        //         Everything about this site was interesting but new to me was using
        //         Mapbox to insert the admin repository and clients regions and calculate
        //         the distance between them, but the Mapbox API with Turf.js made it easy
        //         to do.
        //     </>,
        //     skills: [
        //         "React",
        //         "Firebase",
        //         "Mapbox"
        //     ],
        //     imageKey: "cnc_fl_fe_4",
        //     link: "https://ahmed-cnc.vercel.app/"

        // },
        // {
        //     title: "UPVC company website",
        //     gallery: "smartupvc",
        //     description: <>
        //         &nbsp; &nbsp;  A landing page for a company that offers various upvc services.
        //     </>,
        //     more: <>
        //         <br />
        //         The design was done by figma, then I programmed with gatsbyjs, the
        //         tricky thing I found about creating this site was the dual languages
        //         because I hadn't done that before, but with the react context API, it did
        //         just fine.
        //     </>,
        //     skills: [
        //         "Figma",
        //         "React",
        //         "GatsbyJS",
        //         "styled-components"
        //     ],
        //     imageKey: "smartupvc_0",
        //     link: "https://www.smartupvcwindows.com/"

        // },

    ],
    low: [
        {

            title: "Shapes website",
            gallery: "shapeit",
            skills: [
                "Nextjs",
                "Trpc",
                "Prisma",
                "Tailwindcss",
                "Konva",
            ],
            description: "Website built using Nextjs13 , Trpc , Prisma , Tailwindcss , Konva .",
            imageKey: "shapeit",
            link: " https://shape-it.netlify.app/",
            github: "https://github.com/Da3az/shape-it-app",
        },
        // {

        //     title: "Ecommerce website",
        //     gallery: "ecommerce",
        //     skills: [
        //         "React",
        //         "Redux",
        //         "TypeScript",
        //         "Nodejs",
        //         "Mongoose",
        //         "PassportJS"
        //     ],
        //     description: "An ecommerce website built with MERN stack , list some dummy products , it comes with most ecommerce functionalities .",
        //     imageKey: "ecommerce",
        //     link: "https://mern-ecommerce-ts-app.herokuapp.com",
        //     github: "https://github.com/Da3az/mern-ecommerce",

        // },
        {
            title: "Chat website",
            gallery: "chat",
            description: "A Chat website using socket.IO ,it can create chat rooms and handle discussions between two persons or more in a room  .",
            skills: [
                "React",
                "Redux",
                "Socket.IO",
                "Nodejs",
                "Mongoose",
                "PassportJS"
            ],
            imageKey: "chat",
            // link: "https://mern-socket-chat-app.herokuapp.com",
            github: "https://github.com/Da3az/mern-chat-app",

        },
        // {
        //     title: "Hulu clone website",
        //     gallery: "hulu",
        //     description: "A NextJS clone of the nice Hulu website UI using typescript and TailwindCSS , list shows and movies data for tmdb API's .",
        //     skills: [
        //         "NextJS",
        //         "TypeScript",
        //         "TailwindCSS",
        //     ],
        //     imageKey: "hulu",
        //     link: "https://list-clone.vercel.app/",
        //     github: "https://github.com/Da3az/list-clone",
        // },
        {
            title: "Snake game ",
            gallery: "sanke",
            description: "MERN stack snake game , it saves your personal scores and you can see top 10 of the global scores .",
            skills: [
                "React",
                "Redux",
                "Nodejs",
                "Mongoose",
                "PassportJS"
            ],
            imageKey: "snake",
            // link: "https://mern-snake-game.herokuapp.com",
            github: "https://github.com/Da3az/mern-snake-game",
        },
        // {
        //     title: "Anime website",
        //     gallery: "anime",
        //     description: "An anime website using Jikan api's , users can register and add items to their watchlist .",
        //     skills: [
        //         "React",
        //         "Redux",
        //         "Firebase",
        //         "Graphql",
        //         "Mongoose",
        //         "PassportJS"
        //     ],
        //     imageKey: "anime",
        //     link: "https://react-anime-app.herokuapp.com",
        //     github: "https://github.com/Da3az/react-firebase-anime-app",
        // },
        {
            title: "FlappyBird game ",
            gallery: "flappybird",
            description: "Simple website , where the player can play flappy bird with the ability to change the bird look and the map , the speed of the game increase as the player scores more points .",
            skills: [
                "HTML",
                "CSS",
                "Javascript",
            ],
            imageKey: "flappybird",
            link: "https://da3az.github.io/flappy-bird-game/",
            github: "https://github.com/Da3az/flappy-bird-game",
        },
    ]
}

export const formText = {
    title: "CONTACT",
    button: "Send",
    success: "Email sent successfully",
    fail: "There was something wrong , failed to send the email"
}

export const galleryText = {
    title: "GALLERY",
    description: "A COLLECTION OF SCREENSHOTS",
    filters: [
        {
            key: "_",
            html: <>ALL</>,
        },
        {
            key: "fl",
            html: <>FULL<br /> STACK</>,
        },
        {
            key: "fe",
            html: <>FRONT<br /> END</>,
        },
        {
            key: "game",
            html: <>GAMES</>,
        },
    ],

}