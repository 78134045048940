import React , { useState , useEffect } from "react";
import {
    NavContainer,
    NavMenu,
    NavItem,    
    NavMode,
    NavBurger,
    NavMenuResponsive,
    NavItemResponsive,
    NavLogo
} from './NavComponents'
import {
    Logo,
} from '../../constants'
import { Link , navigate } from "gatsby";
import { links } from "../../texts";
import scrollTo from 'gatsby-plugin-smoothscroll';
import { StaticQuery,graphql } from "gatsby"


const Nav = ({ theme , setTheme, pathname , cvUrl}) => {
    const [selected, setSelected] = useState(0)
    const [close, setClose] = useState(false)
    const [scroll,setScroll] = useState(false)
  
    useEffect(() => {
        changeNav()
        window.addEventListener("scroll",changeNav)
        if(pathname.includes('/gallery')){
            setSelected(4)
        }
    },[])

    function switchMode(){
        var mode = "dark"
        if(theme === "dark") mode = "light"
         setTheme(mode)
    }

    async function navSelect(element,index){
        setSelected(index);
        if(element.page !== pathname && element.page === '/gallery'){
            navigate('/gallery')
            scrollTo(element.link);
        }else if(element.page !== pathname && element.page === '/'){
            navigate('/')
        }
        setTimeout(() => {
            scrollTo(element.link);
        },100)
        

    }

   

    const changeNav = () =>{
        var current = "";
        const sections = document.getElementsByTagName('section')
        Object.keys(sections).find((key) => {
            const section = sections[key]
            const sectionTop = section.offsetTop;
            if(section.id === 'gallery' || section.id === 'show'){
                current = 4; 
            }else if (window.pageYOffset >= sectionTop - 60) {
                current = key; 
            }
                
        });
        setSelected(parseInt(current))
        if(window.scrollY >= 100){
            setScroll(true)
        }else{
            setScroll(false)
        }
    }

    return (
        <NavContainer scroll={scroll} theme={theme}>
            <NavLogo onClick={() => navSelect({link:"#home",page:"/"},0)} theme={theme}>
                <Logo />
            </NavLogo>
            <NavMode theme={theme} onClick={() => switchMode()}>
                    <span/>
                    {/* <span/> */}
            </NavMode>
            <NavBurger theme={theme} close={close} onClick={() => setClose((close) => !close)}>
                <span></span>
                <span></span>
                <span></span>
            </NavBurger>
            <NavMenuResponsive theme={theme} close={close}>
                {
                    links.map( (e,i) => 
                        <NavItemResponsive onClick={() => navSelect(e,i)} theme={theme}  key={`nav_item_responsive_${i}`}  selected={selected === i} >
                            <div >
                                {e.title}
                            </div>    
                        </NavItemResponsive>
                    )
                }
                <NavItemResponsive theme={theme}>
                    <div >
                        <a href={cvUrl} target="_blank" ><span>C</span>V</a>
                    </div>  
                </NavItemResponsive>
            </NavMenuResponsive>
            <NavMenu theme={theme}>
                {
                    links.map( (e,i) => 
                        <NavItem  theme={theme} key={`nav_item_${i}`} onClick={() => navSelect(e,i)} selected={selected === i}>
                            <Link to={e.link}>
                                {e.title}
                            </Link>    
                        </NavItem>
                    )
                }
                <NavItem theme={theme}>
                    <div >
                        <a href={cvUrl} target="_blank" ><span>C</span>V</a>
                    </div>  
                </NavItem>
            </NavMenu>
        </NavContainer>
    )
}


const NavWrapper = ({ theme , setTheme, pathname}) => {

    return (
      <StaticQuery 
        query={query
        }
        render = { data => <Nav  pathname={pathname} cvUrl={data.file.publicURL} theme={theme} setTheme={setTheme}/>}
      />
    )
}

export const query = graphql`
    query NavQuery {
      file(relativePath: {eq: "SALAH_SENNOUSSI_CV.pdf"}) {
        id
        publicURL
      }
    }
`

export default NavWrapper
