import styled from "styled-components";
import {
    colors,
    fonts
} from '../../constants'

export const FormContainer = styled.form`
    width: 100%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${({theme}) => colors[theme].font};
    input,textarea{
        padding: 10px 20px;
        border: none;
        font-size: ${fonts.fixed[3]};
        /* border-bottom: 2px solid;
        border-color: ${({theme}) => colors[theme].border}; */
        transition: border .2s ease-in;
        width: 500px;
        margin: 10px 0;
        @media screen and (max-width:400px){
            width: 70vw;
            padding: 10px 20px;
            font-size: ${fonts.fixed[5]};
            padding: 5px 10px;
        }
        background: ${({theme}) => colors[theme].font+ '0F'};
        color: ${({theme}) => colors[theme].font};
        outline: none;
        box-shadow:${({theme}) => `0px -2px 0px transparent inset`};
        transition:border .2s ease-in,color .2s ease-in,box-shadow .2s ease-in; 
    }
    input:focus,textarea:focus{
        box-shadow:${({theme}) => `0px -2px 0px ${colors[theme].border} inset`}; ;
    }
    button{
        background-color: ${({theme}) => colors[theme].font + '0F' };
        transition:  background-color .2s ease-in;
        color: ${({theme}) => colors[theme].border };
        border: ${({theme}) => `2px solid ${colors[theme].border}` };
        transition:background-color .2s ease-in; 
        :hover{
            background-color:transparent} 
        }
        
   
`

export const FormInput = styled.input`
    display: flex;
    padding: 5px 10px;
    margin: 10px;
    height: 30px;
`

export const FormTextArea = styled.textarea`
    display: flex;
    padding: 5px 10px;
    margin: 10px;
    height: 200px;
`

export const FormButton = styled.div`
    display: flex;
    width: 500px;
    padding: 10px 20px;
    @media screen and (max-width:400px){
        width: 70vw;
        padding: 10px 20px;
        font-size: ${fonts.fixed[5]};
        padding: 5px 10px;
    }
    button{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 40px;
        margin: 20px 0;
        width: 160px;
        cursor: pointer;
        font-size: ${fonts.fixed[3]};
        div{
            position: absolute;
            right: 10px;
        }
        @media screen and (max-width:400px){
                justify-content: center;
                width: 20vw;
                padding: 5px 10px;
                font-size: ${fonts.fixed[5]} ;
                padding: 5px 10px;
                margin-left: 10px;
        }
    }
    
`

export const FormRights = styled.p`
    display: flex;
    justify-content: center;
    width:60vw;
    margin: auto;
    padding: 50px;
    font-size: ${fonts.fixed[5]} ;
    color:${({theme}) => colors[theme].font} ;
    letter-spacing: .2vmin;
`

