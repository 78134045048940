import React, { useContext, useEffect, useState } from "react"
// import Navbar from "../components/Navbar"
// import Footer from "../components/Footer"
import { GlobalContext } from "../GlobalContext"
import NavWrapper from "../components/Nav"
import { colors } from "../constants"
import Footer from "../components/Footer"
export default function Layout({ children , home, pathname}) {
  const { theme , setTheme } = React.useContext(GlobalContext)

  return (
      <div id="layout" style={
        {
          backgroundColor: colors[theme].bg
        }
      }>
        {/* {pathname !== '/gallery' && <Nav theme={theme}  setTheme={setTheme}/>} */}
        <NavWrapper theme={theme} pathname={pathname} setTheme={setTheme}/>
        {children}
        <Footer theme={theme}/>
      </div>
  )
}

