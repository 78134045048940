import React from "react";
import styled from "styled-components";
import { colors,fonts } from "../../constants";

export const NavContainer = styled.div`
    display: flex;
    padding: ${({scroll}) => scroll ? "5px 50px" : "20px 50px"};
    justify-content: space-between;
    align-items: center;
    color: ${({theme}) => colors[theme].font};
    border-bottom: 4px solid ${colors.font};
    transition:color .2s ease-in;
    font-weight: bold;
    position: fixed;
    width: calc(100% - 100px);
    z-index: 2;
    background: ${({theme}) => colors[theme].bg};;
    transition:color .2s ease-in,border .2s ease-in,background-color .2s ease-in,padding .2s ease-in;
`

export const NavLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 0;
    cursor: pointer;
    svg{
        width: 40px;
        height: 40px;
        #big{
            stroke: ${({theme}) => colors[theme].font};
            fill: ${({theme}) => colors[theme].bg};
        }
        #small{
            stroke: ${({theme}) => colors[theme].bg};
            fill: ${({theme}) => colors[theme].bg};
        }
        #cletter{
            stroke: ${({theme}) => colors[theme].font};
            fill: ${({theme}) => colors[theme].font};
        }
    }
`

export const NavMenu = styled.ul`
    display: flex;
    justify-content: space-between;
    list-style: none;
    @media screen and (max-width:1300px) {
      display: none;
    }
`
export const NavItem = styled.li`
        margin: 0px 50px;
        position: relative;
        ::after{
            content: '';
            position: absolute;
            width: 4px;
            height: 4px;
            background-color: ${({theme}) => colors[theme].border};
            bottom: ${({selected}) => selected ? '2px' : '150px'};
            right: -6px;
            border-radius: 50%;
            transition: bottom .5s ease-in, background-color .2s ease-in;;
        }
        a{
            text-decoration: none;
            color: inherit;
            span{
                transition: color .2s ease-out;
            }
            :hover{
                span{
                    color: ${({theme}) => colors[theme].border};
                }
            }
        }
`
export const NavBurger = styled.div`
    position: relative;
    z-index: 20;    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    width: 30px;
    height: 30px;
    span{
        background: ${({theme}) => colors[theme].border};
        width: 30px;
        height: 2px;
        margin-bottom:${({close}) => !close ? '3px' : '0px'};
        transition: margin .2s ease-in, width .2s ease-in, transform .2s ease-in, background-color .2s ease-in;
        transform-origin: center;
        position: ${({close}) => close ? 'absolute' : 'unset'};
        top: calc(50% - 1px);
        left: calc(50% - 15px);

    }
    span:nth-child(1){
        margin-left:${({close}) => !close ? '10px' : '0px'};
        transform:${({close}) => close ? 'rotate(135deg)' : 'rotateX(0deg)'};
        
    }
    span:nth-child(2){
        margin-left:${({close}) => !close ? '5px' : '0px'};
        width:${({close}) => !close ? '30px' : '0px'};
    }
    span:nth-child(3){
        margin-left:0px;
        transform:${({close}) => close ? 'rotate(45deg)' : 'rotateX(0deg)'};
    }
    :hover{
        span{
            margin-left: 0
        }
    }
    display: none;
    @media screen and (max-width:1300px) {
      display: flex;
    }
    
`

export const NavMode = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    transition: border .2s ease-in , background-color .2s ease-in;
    border-bottom: 2px solid;
    border-color: ${
        ({theme}) => {
                    var mode = "dark"
                    if(theme === "dark") mode = "light"
                    return colors[mode].bg
        }
    };
    width: 20px;
    :hover{
        span{
            transform: translateY(-2px);
        };
    };
    span{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2px;
        height: 15px;
        width: 15px;
        border-radius: 2px;
        background: 
        ${
                ({theme}) => {
                    var mode = "dark"
                    if(theme === "dark") mode = "light"
                    return colors[mode].border
                }
        };
        transition: transform .2s ease-out, background-color .2s ease-in, border .2s ease-in;
        transform-origin: center;
        
    };
`

// Responsive Menu 
export const NavMenuResponsive = styled.ul`
    display: none;
    position: fixed;
    z-index: 19;
    background-color: ${({theme}) => colors[theme].bg};
    border-left: ${({theme}) => `4px solid ${colors[theme].font}`};
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    left: calc(100% - 180px);
    top: 0px;
    list-style: none;
    width: 180px;
    height: 100vh;
    margin: 0;
    padding: 0;
    padding-top: 100px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
    transform:${({close}) => close ? 'translateX(0px)' : 'translateX(200px)'};
    transition: transform .5s ease-out, background-color .2s ease-in;
    @media screen and (max-width:1300px) {
      display: flex;
    }
    @media screen and (max-width:400px) {
        font-size: ${fonts.responsive[2]};
    }

`

export const NavItemResponsive = styled.li`
        margin-bottom: 50px;
        position: relative;
        cursor: pointer;
        ::after{
            content: '';
            position: absolute;
            width: 4px;
            height: 4px;
            background-color: ${({theme}) => colors[theme].border};;
            bottom: 2px;
            right: ${({selected}) => selected ? '-6px' : '-150px'};
            border-radius: 50%;
            transition: right .5s ease-in, background-color .2s ease-in;
        }
        a{
            text-decoration: none;
            color: inherit;
            span{
                transition: color .5s ease-out;
            }
            :hover{
                span{
                    color: ${({theme}) => colors[theme].border};
                    transition: color .2s ease-in;
                }
            }
        }
`

